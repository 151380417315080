import Vue from 'vue'
import VueRouter from 'vue-router'
import { Local } from '@/utils/storage'

const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return routerPush.call(this, location).catch(err => { })
}

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "/",
    redirect: '/petCommunity'
  },
  {
    path: "/petCommunity",
    name: "petCommunity",
    redirect: "/petCommunity/communityClass",
    component: () => import("@/views/petCommunity"),
    children: [
      {
        path: "/petCommunity/communityClass",
        name: "communityClass",
        meta: {
          isNeedLogin: false,
        },
        component: () => import("@/views/petCommunity/communityClass"),
      },
      {
        path: "/petCommunity/myCollection",
        name: "myCollection",
        meta: {
          isNeedLogin: true,
        },
        component: () => import("@/views/petCommunity/myCollection"),
      },
      {
        path: "/petCommunity/classDetails",
        name: "classDetails",
        meta: {
          isNeedLogin: false,
        },
        component: () => import("@/views/petCommunity/classDetails"),
      },
      {
        path: "/petCommunity/myCommunity",
        name: "myCommunity",
        meta: {
          isNeedLogin: true,
        },
        component: () => import("@/views/petCommunity/myCommunity"),
      },
      {
        path: "/petCommunity/ohterCommunity",
        name: "ohterCommunity",
        meta: {
          isNeedLogin: true,
        },
        component: () => import("@/views/petCommunity/ohterCommunity"),
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      isNeedLogin: false,
    },
    component: () => import('@/views/login')
  },
  {
    path: '/petArea',
    name: 'petArea',
    meta: {
      isNeedLogin: false,
    },
    component: () => import('@/views/petArea')
  },
  {
    path: '/home',
    name: '/home',
    meta: {
      isNeedLogin: false
    },
    component: () => import('@/views/home')
  },
  {
    path: '/brands/brandClass',
    name: 'brandClass',
    meta: {
      isNeedLogin: false
    },
    component: () => import('@/views/brands/brandClass'),
  },
  {
    path: '/brands/brandsDetails',
    name: 'brandsDetails',
    meta: {
      isNeedLogin: false
    },
    component: () => import('@/views/brands/brandsDetails'),
  },
  {
    path: '/productDetails',
    name: 'productDetails',
    meta: {
      isNeedLogin: false
    },
    component: () => import('@/views/productDetails')
  },
  {
    path: '/personalCenter',
    name: 'personalCenter',
    meta: {
      isNeedLogin: true,
    },
    component: () => import('@/views/personalCenter')
  },
  {
    path: '/myOrder',
    name: 'myOrder',
    meta: {
      isNeedLogin: true,
    },
    component: () => import('@/views/myOrder')
  },
  {
    path: '/shoppingCart',
    name: 'shoppingCart',
    meta: {
      isNeedLogin: true,
    },
    component: () => import('@/views/shoppingCart'),
  },
  {
    path: '/confirmOrder',
    name: 'confirmOrder',
    meta: {
      isNeedLogin: true,
    },
    component: () => import('@/views/confirmOrder'),
  },
  {
    path: '/addressList',
    name: 'addressList',
    meta: {
      isNeedLogin: true
    },
    component: () => import('@/views/addressList'),
  },
  {
    path: '/addAddress',
    name: 'addAddress',
    meta: {
      isNeedLogin: true
    },
    component: () => import('@/views/addAddress'),
  },
  {
    path: '/paymentResult/paymentSuccess',
    name: 'paymentSuccess',
    meta: {
      isNeedLogin: true
    },
    component: () => import('@/views/paymentResult/paymentSuccess'),
  },
  {
    path: '/paymentResult/paymentFail',
    name: 'paymentFail',
    meta: {
      isNeedLogin: true
    },
    component: () => import('@/views/paymentResult/paymentFail'),
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    meta: {
      isNeedLogin: true
    },
    component: () => import('@/views/orderDetail'),
  },
  {
    path: "/blog",
    name: "blog",
    component: () => import("@/views/blog"),
    redirect: "/blog/blogContent",
    children: [
      {
        path: "blogContent",
        name: "blogContent",
        meta: {
          isNeedLogin: false,
        },
        component: () => import("@/views/blog/blogContent"),
      },
      {
        path: "blogArticle/:classId/:id",
        name: "blogArticle",
        meta: {
          isNeedLogin: false,
        },
        component: () => import("@/views/blog/blogArticle"),
      },
    ],
  },
  {
    path: '/goodsEvaluate',
    name: 'goodsEvaluate',
    meta: {
      isNeedLogin: true
    },
    component: () => import('@/views/goodsEvaluate'),
  },
  {
    path: '/myCollect',
    name: 'myCollect',
    meta: {
      isNeedLogin: true
    },
    component: () => import('@/views/myCollect'),
  },
  {
    path: '/myCoupon',
    name: 'myCoupon',
    meta: {
      isNeedLogin: true
    },
    component: () => import('@/views/myCoupon'),
  },
  {
    path: '/notice',
    name: 'notice',
    meta: {
      isNeedLogin: true
    },
    component: () => import('@/views/notice'),
  },
  {
    path: '/personalInformation',
    name: 'personalInformation',
    meta: {
      isNeedLogin: true
    },
    component: () => import('@/views/personalInformation'),
  },
  {
    path: '/securitySetting',
    name: 'securitySetting',
    meta: {
      isNeedLogin: true
    },
    component: () => import('@/views/securitySetting'),
  },
  {
    path: '/allCoupon',
    name: 'allCoupon',
    meta: {
      isNeedLogin: true
    },
    component: () => import('@/views/allCoupon'),
  },
  {
    path: "/evaluate",
    name: "evaluate",
    meta: {
      isNeedLogin: true,
    },
    component: () => import("@/views/evaluate"),
  },
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

// 路由加载前
router.beforeEach(async (to, from, next) => {
  console.log(to, 'to')
  const token = Local.get("token");
  const { isNeedLogin } = to.meta;

  if (isNeedLogin && !token) {
    next(`/login?redirect=${to.path}&params=${JSON.stringify(to.query ? to.query : to.params)}`);
  } else {
    if (token && to.path === '/login') {
      next('/home');
    } else {
      next();
    }
  }

});

export default router
