<script>
import { mapGetters } from "vuex";
export default {
  methods: {
    // 路由跳转
    jumpPage(path) {
      this.$router.push(path);
    },
    // 跳转外链
    jumpLink(url) {
      if (!url) return;
      window.open(url);
    },
    // 全局toast提示
    toast({ type = null, message = "", forbidClick = true }) {
      if (type == "success") {
        this.$toast.success({ message, forbidClick });
      }
      if (type == "error") {
        this.$toast.fail({ message, forbidClick });
      }
      if (type == null) {
        this.$toast({ message, forbidClick });
      }
    },
    // 全局loading
    showLoading() {
      this.$toast.loading({ duration: 0, forbidClick: true });
    },
    // 全局清除toast
    closeLoading() {
      this.$toast.clear();
    },
  },
  computed: {
    ...mapGetters(["lang"]),
  },
};
</script>
