import Vue from 'vue'
import Vuex from 'vuex'
import { zh_en, zh_cn } from "@/lang";
import { Local } from "@/utils/storage";
import { areaList } from "@/plugins/area";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 语言类型
    langType: 'zh-cn',
    // 语言文本对象
    langData: zh_cn,
    // 账号状态
    accountState: 0 /* 0：未冻结 1：已冻结  */,
    // 地区区号
    areaList,
    // 底部信息
    footer: null,
    // 用户信息
    userInfo:null,
  },
  getters: {
    langType: state => state.langType,
    lang: state => state.langData,
    accountState: state => state.accountState,
    areaList: state => state.areaList,
    footer: state => state.footer,
    userInfo: state => state.userInfo,
  },
  mutations: {
    // 设置语言
    SET_LANG(state, val) {
      if (val === null) return Local.set('lang', 'zh-cn')

      if (val === 'zh-en') {
        state.langType = val
        state.langData = zh_en
      }
    },
    // 更改账号状态
    CHANGE_ACCOUNT_STATE(state, val) {
      state.accountState = val
    },
    // 保存底部信息
    SET_FOOTER(state, val) {
      state.footer = val
    },
    // 用户信息
    SET_USERINFO(state, val) {
      state.userInfo = val
    },
  },
  actions: {
  },
  modules: {
  }
})
