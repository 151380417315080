import Vue from "vue";
import "vant/lib/index.css";

import {
    Button,
    Image as VanImage,
    Toast,
    Popup,
    Picker,
    Field,
    Checkbox,
    Notify,
    Loading,
    Tab,
    Tabs,
    List,
    Sku,
    Icon,
    Collapse,
    CollapseItem,
    Overlay,
    Stepper,
    RadioGroup,
    Radio,
    Swipe,
    SwipeItem,
    Popover,
    Rate,
    ImagePreview,
    CheckboxGroup,
    Dialog,
    Uploader,
    Calendar,
    Form,
    Lazyload,
    Pagination ,
    Switch,
    Badge,
} from 'vant';

Vue.use(Button);
Vue.use(VanImage);
Vue.use(Toast);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Field);
Vue.use(Checkbox);
Vue.use(Notify);
Vue.use(Loading);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(List);
Vue.use(Sku);
Vue.use(Icon);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Overlay);
Vue.use(Stepper);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Popover);
Vue.use(Rate);
Vue.use(ImagePreview);
Vue.use(CheckboxGroup);
Vue.use(Dialog);
Vue.use(Calendar);
Vue.use(Form);
Vue.prototype.$dialog = Dialog
Vue.use(Uploader);
Vue.use(Lazyload);
Vue.use(Pagination)
Vue.use(Switch);
Vue.use(Badge);
Vue.prototype.$toast = Toast
//elment-ui $message => vant-ui $toast
Vue.prototype.$message = function ({ message, type, ...others }) {
  const messageTransfer = {
    success: "success",
    warning: "fail",
    danger: "fail",
  };
  this.$nextTick(function(){
    this.$toast({
      message,
      type: messageTransfer[type] || type,
      ...others,
    })
  })
};
Object.keys(Vue.prototype.$toast).forEach((v) => {
  if (v === "install") return;
  Vue.prototype.$message[v] = Vue.prototype.$toast[v];
});
