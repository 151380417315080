<template>
  <div id="app">
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import { Local } from "./utils/storage";

export default {
  name: "app",
  data() {
    return {};
  },
  methods: {
    // 获取默认语言
    getLang() {
      const lang = Local.get("lang");

      this.$store.commit("SET_LANG", lang);
    },
    // 初始化
    init() {
      this.getLang();
    },
  },
  created() {
    this.init();
  },
};
</script>

<style lang="less">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  display: none;
}

html,
body,
#app {
  width: 100%;
  height: 100%;
  user-select: none;
  overflow: auto;
}
</style>
