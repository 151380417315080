import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/vant.js'
import 'lib-flexible'
import './utils/flexible'
import mixin from './mixin/index.vue'
// import VConsole from 'vconsole';
// if(process.env.VUE_APP_ENV === "'development'"){
//   new VConsole();
// }
Vue.mixin(mixin)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
